<template>
  <CNavbar expandable="md" color="white">
    <CContainer class="menu-container">
      <CToggler in-navbar @click="collapsed = !collapsed">
        <CIcon name="cil-menu" />
      </CToggler>
      <CNavbarBrand href="/">
        <img height="75" src="/img/brand/on-the-rolly-logo.png">
      </CNavbarBrand>
      <CCollapse :show="collapsed" navbar>
        <!-- Right aligned nav items -->
        <CNavbarNav class="ml-auto">
          <CNavItem>
            <CLink :active="$route.name == 'Home'" to="/">
              Home
            </CLink>
          </CNavItem>
          <CNavItem>
            <CLink :active="$route.name == 'Contact'" to="/contact">
              Contact
            </CLink>
          </CNavItem>
          <CNavItem>
            <CLink to="/auth/login">
              Login
            </CLink>
          </CNavItem>
        </CNavbarNav>
      </CCollapse>
    </CContainer>
  </CNavbar>
</template>

<script>
export default {
  name: 'Menu',
  data() {
    return {
      collapsed: false
    }
  },

}
</script>

<style scoped>
nav {
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, .06) !important;
  padding: 0;
}

.menu-container svg {
  width: 30px !important;
  height: 30px !important;
}

.navbar-collapse {
  padding-left: 20px;
  padding-right: 20px;
}

::v-deep .nav-link a {
  font-size: 15px;
  font-weight: 600;
  color: #8c8aac !important;
}

::v-deep .nav-link a.active {
  color: black !important;
  font-weight: bold;
}

::v-deep .navbar-brand {
  color: #8c8aac !important;
}

::v-deep .navbar-toggler svg {
  color: #8c8aac !important;
  outline: none;
}

::v-deep .navbar-toggler:focus,
::v-deep .navbar-toggler:active {
  outline: none;
}
</style>