<template>
  <CFooter
  :fixed="false">
    <div class='text-center'>
      <span class="ml-1">Development & Hosting By </span>
      <a class='custom-link' href="https://kersversdigital.nl" target="_blank">Kersvers Digital.</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
  footer div {
    width: 100%;
  }
</style>
