<template>
  <div class="c-app">
    <Menu />
    <div class="c-body" id="body">
      <main class="c-main">
        <CContainer fluid>
          <AlertBox />
          <transition name="fade">
            <router-view></router-view>
          </transition>
        </CContainer>
      </main>
    </div>
    <!-- <Footer/> -->
  </div>
</template>

<script>

import Menu from './Menu'
import Footer from './Footer'

export default {
  name: 'TheContainer',
  components: {
    Menu,
    Footer
  },
  watch: {
  },
  data() {
    return {

    }
  },
  created: function () {

  },
  methods: {
  }
}

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Courier+Prime&display=swap');

.c-app {
  font-family: 'Courier Prime' !important;
}

.c-app {
  display: block;
  background-color: white;
}

.c-main {
  padding: 0px !important;
}

.c-main .container-fluid {
  padding: 0px !important;
}

::v-deep .mt-20 {
  margin-top: 20px;
}

::v-deep .mt-40 {
  margin-top: 40px;
}

::v-deep .mt-60 {
  margin-top: 60px;
}

::v-deep .hide-desktop {
  display: none;
}

::v-deep .hide-mobile {
  display: block;
}

::v-deep .custom-link {
  font-weight: bold;
  color: black;
}

::v-deep .section.grey-bg {
  background-color: #fbfafd;
}

::v-deep img {
  max-width: 100%;
  object-fit: cover;
}

::v-deep .custom-btn {
  padding: 12px 30px;
  background-color: black !important;
  border: 1px solid black !important;
  color: #fff;
  font-size: 15px !important;
  transition: all .5s;
  font-weight: 700;
}

::v-deep .custom-btn .outline {
  background-color: black !important;
  border: 1px solid black !important;
}

::v-deep .custom-btn:hover {
  transform: translateY(-2px);
  transition: all .5s;
}

::v-deep .custom-link {
  font-weight: 600;
}

::v-deep h2 {
  font-weight: 700;
  font-size: 1.75rem;
  color: #3b3950;
}

::v-deep h5 {
  font-weight: 700;
  font-size: 1.5rem;
  color: #3b3950;
}

::v-deep .text-muted {
  color: #9e9cb9 !important;
  line-height: 1.8;
  font-weight: 500;
  font-size: 1rem;
}

::v-deep .section {
  padding-top: 80px;
  padding-bottom: 80px;
}

::v-deep .box {
  box-shadow: 0 3px 12px rgba(6, 11, 47, .06);
  margin-bottom: 30px;
  border-radius: .25rem !important;
  padding: 1.5rem !important;
  transition: all .5s;
}

::v-deep .box:hover {
  box-shadow: 0 5px 18px rgba(76, 73, 100, 0.4);
  transform: translateY(-6px);
  margin-bottom: 30px;
}

::v-deep .box:hover h5,
::v-deep .box:hover p {
  color: #fff;
}

::v-deep .block-header {
  margin-bottom: 40px;
}

@media only screen and (max-width: 575px) {
  ::v-deep .hide-mobile {
    display: none;
  }

  ::v-deep .hide-desktop {
    display: block;
  }
}</style>